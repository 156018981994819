import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { Link } from "gatsby";
import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { Container, Image, Menu, Responsive } from "semantic-ui-react";

import headerLogoLightBackground from "../../../data/img/lattice-logo-2019-newText-lightBackground.svg";
import { MenuProps } from "../Menu";

export default class HeaderMenu extends React.Component<MenuProps> {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    const { mobileMenuOpen } = this.state;
    this.setState({ mobileMenuOpen: !mobileMenuOpen });

    if (mobileMenuOpen) {
      clearAllBodyScrollLocks();
    } else {
      scrollTo(0, 0);
      disableBodyScroll(window.document.body);
    }
  }

  render() {
    const { items, pathname } = this.props;

    return (
      <Container className="header">
        <div className="menu-brand">
          <Image
            alt="Lattice logo"
            src={headerLogoLightBackground}
            className="ui small middle aligned image"
            centered
            as={Link}
            to="/"
          />
        </div>

        <Responsive
          size="large"
          pointing
          secondary
          className="computer large screen widescreen only menubar"
          as={Menu}
        >
          {items.map((item) => {
            const active = item.exact
              ? pathname === item.path
              : pathname.startsWith(item.path);

            // external link
            if (item.path.startsWith("http")) {
              return (
                <Menu.Item className="a" key={item.path} active={false}>
                  <a href={item.path}>{item.name}</a>
                </Menu.Item>
              );
            }

            // internal link
            return (
              <Menu.Item
                as={Link}
                name={item.name}
                to={item.path}
                key={item.path}
                active={active}
              />
            );
          })}
        </Responsive>

        {/* Mobile Dropdown Menu */}
        <div className="mobile tablet only mobile-header">
          <label className="navicon-button" onClick={this.toggleMobileMenu}>
            <CSSTransition
              in={this.state.mobileMenuOpen}
              timeout={350}
              classNames="navicon-animation"
            >
              <span className="navicon"></span>
            </CSSTransition>
          </label>

          <CSSTransition
            in={this.state.mobileMenuOpen}
            timeout={350}
            classNames="dropdown-animation"
            unmountOnExit
          >
            <div className="mobile-dropdown">
              {items.map((item) => {
                // external link
                if (item.path.startsWith("http")) {
                  return (
                    <div key={item.path + "mobile"}>
                      <a href={item.path}>{item.name}</a>
                    </div>
                  );
                }

                // internal link
                return (
                  <div key={item.path + "mobile"}>
                    <Link to={item.path} onClick={this.toggleMobileMenu}>
                      {item.name}
                    </Link>
                  </div>
                );
              })}
            </div>
          </CSSTransition>
        </div>
      </Container>
    );
  }
}
