import "prismjs/themes/prism-okaidia.css";
import * as React from "react";
import { Segment, Sidebar } from "semantic-ui-react";
import Footer from "../components/Footer/Footer";
import "../css/footer.css";
import "../css/responsive.css";
import "../css/semantic.min.css";
import "../css/styles.css";
import "../css/team.css";
import HeaderMenu from "./HeaderMenu/HeaderMenu";

export const menuItems = [
  { name: "Home", path: "/", exact: true, icon: "home" },
  { name: "Solutions", path: "/solutions", exact: true, icon: "info circle" },
  { name: "Team", path: "/team", exact: true, icon: "info circle" },
  {
    exact: false,
    icon: "external",
    name: "Careers",
    path: "/careers",
    // path: "https://latticeautomation.recruiterbox.com/",
  },
  {
    exact: false,
    icon: "external",
    name: "Blog",
    path: "https://medium.com/lattice-automation",
  },
];

export interface LayoutProps {
  location: {
    pathname: string;
  };
  children: any;
}

const Layout = (props: LayoutProps) => {
  const { pathname } = props.location;
  const isHome = pathname === "/";

  return (
    <Sidebar.Pushable as={Segment}>
      <Sidebar.Pusher style={{ minHeight: "100vh" }}>
        {/* Header */}
        {isHome ? null : <HeaderMenu pathname={pathname} items={menuItems} />}

        {/* Render children pages */}
        <div>{props.children}</div>

        {/* Footer */}
        <Footer />
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default Layout;

export const withLayout = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) =>
  class WithLayout extends React.Component<P & LayoutProps> {
    render() {
      return (
        <Layout location={this.props.location}>
          <WrappedComponent {...this.props} />
        </Layout>
      );
    }
  };
